import Vue from 'vue'
import i18n from '../i18nVeeValidate'
import Vuex from 'vuex'
import router from '@/router'
import authentication from './modules/authentication'
import alert from './modules/alert'
import profile from './modules/profile'
import subscription from './modules/subscription'
import session from './modules/session'
import payment from './modules/payment'
import temporaryAccess from './modules/temporaryAccess'
import admin from './modules/admin/admin'
import parkings from './modules/parkings'
import requests from './modules/requests'
import { find } from 'lodash/fp'
import { UILanguages } from '../config'
import graphqlClient from '@/api/db'
import gql from 'graphql-tag'
import { logger } from '../logger'

// Import { filter, omit } from 'lodash/fp'

Vue.use(Vuex)

const currentLanguage = () =>
	find({ key: localStorage.getItem('cycloLanguage') || 'fr' }, UILanguages) ||
	find({ key: 'fr' }, UILanguages)

const initialState = () => {
	return {
		mapsLanguage: currentLanguage(),
		language: currentLanguage(),
		serverTime: null,
		error: '',
		fromPath: null,
	}
}

const state = initialState()

const getters = {}

const mutations = {
	setLanguage(state, language) {
		logger.debug('setting lang', language.value)
		localStorage.setItem('cycloLanguage', language.value)
		state.language = language
	},
	setError(state, error) {
		state.error = error
	},

	storeFromPath(state, path) {
		state.fromPath = path
	},

	setServerTime(state, serverTime) {
		state.serverTime = serverTime
	},
}

const actions = {
	selectLanguage: ({ commit }, language) => {
		i18n.locale = language.value
		commit('setLanguage', language)
	},
	goAfterAuth: ({ state }) => {
		logger.debug('go after auth', state.subscription.direct)
		const subscriptionModal = localStorage.getItem('subscriptionModal')
		if (state.subscription.direct) {
			router.push({ name: 'subscription', params: { subType: state.subscription.direct } })
		} else if (state.session.sessionCreate && state.session.sessionCreate.length > 0) {
			router.push({ name: 'MainDoorQr' })
		} else if (state.session.sessionStart && state.session.sessionStart.length > 0) {
			router.push({ name: 'SessionStartQr' })
		} else if (state.session.sessionMdExt && state.session.sessionMdExt.length > 0) {
			router.push({ name: 'MainDoorExtQr' })
		} else if (state.session.sessionActive && state.session.sessionActive.length > 0) {
			router.push({ name: 'HowSessionStart' })
		} else if (state.session.sessionStop && state.session.sessionStop.length > 0) {
			router.push({ name: 'EnteringQr' })
		} else if (state.session.sessionEnd && state.session.sessionEnd.length > 0) {
			router.push({ name: 'SessionEndQr' })
		} else if (state.fromPath) {
			router.push(state.fromPath)
		} else if (subscriptionModal) {
			router.push({ name: 'parkings' })
		} else {
			router.push({ name: 'profile' })
		}
	},

	async logError({ dispatch }, error) {
		await graphqlClient.mutate({
			mutation: gql`
				mutation LogError($error: String!) {
					logError(error: $error)
				}
			`,
			variables: {
				error,
			},
		})
		dispatch('alert/error', 'Something went wrong', { root: true })

		return true
	},
}

export default new Vuex.Store({
	state,
	getters,
	mutations,
	actions,
	modules: {
		authentication,
		alert,
		profile,
		temporaryAccess,
		subscription,
		admin,
		parkings,
		requests,
		session,
		payment,
	},
})
