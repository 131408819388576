<template>
	<div class="payments">
		<Loading v-if="loading" />
		<div v-if="view != 'profile'" style="margin-bottom: 72px" class="profile-title-section">
			<h2 style="color: #1b388a; font-size: 32px; line-height: 54px; font-family: 'Poppins Bold'">
				<!-- Information de paiement -->
				{{ $t('profile.paymentMethods.paymentInfoLabel') }}
			</h2>
		</div>
		<div>
			<div v-for="(card, index) in creditCards" :key="index">
				<div v-if="view == 'profile'" class="credit-card-profile">
					<div class="card-icon">
						<!-- Visa -->
						<img
							v-if="card.details.cardLabel == 'Visa'"
							src="../../assets/images/VisaCardLogo.png"
							class="YQ4gaf zr758c wA1Bge"
							height="31"
							width="56"
							alt=""
							data-csiid="15"
							data-atf="1"
						/>
						<!-- Mastercard -->
						<img
							v-else-if="card.details.cardLabel == 'Mastercard'"
							src="../../assets/images/MasterCardLogo.png"
							class="YQ4gaf zr758c wA1Bge"
							height="34"
							width="56"
							alt=""
							data-csiid="13"
							data-atf="1"
						/>
						<!-- American Express -->
						<img
							v-else="card.details.cardLabel == 'American Express'"
							src="../../assets/images/ExpressCardLogo.png"
							class="YQ4gaf zr758c wA1Bge"
							height="56"
							width="56"
							alt=""
							data-csiid="14"
							data-atf="1"
						/>
						<div class="card-number">
							{{ card.details.cardLabel }} ••••
							{{ card.details.cardNumber }}
							<br/> <span class="expires">Expires {{ card.details.cardExpiryDate }}</span>
						</div>
					</div>
					<div class="card-details">
						<div class="card-details-box">
							<input
								id="html"
								class="radio"
								:checked="card.default"
								type="radio"
								name="default"
								value="1"
								@click="setDefaultCard(card.customerId, card.id)"
							/>
							<div v-if="card.default == true" class="default-text">
								{{ $t('profile.paymentMethods.default') }}
							</div>
							<div v-if="card.default == false" class="default-text" style="visibility: hidden">
								{{ $t('profile.paymentMethods.default') }}
							</div>
						</div>
						<!-- card.default != true && -->
						<div
							class="delete-card"
							v-if="card.default != true && creditCards.length > 1"
							@click="deleteCard(card.customerId, card.id)"
						>
							{{ $t('profile.paymentMethods.deletePaymentMethodButton') }}
						</div>
					</div>
				</div>
				<div v-else class="credit-card">
					<div class="card-icon">
						<!-- Visa -->
						<img
							v-if="card.details.cardLabel == 'Visa'"
							src="../../assets/images/VisaCardLogo.png"
							class="YQ4gaf zr758c wA1Bge"
							height="31"
							width="56"
							alt=""
							data-csiid="15"
							data-atf="1"
						/>
						<!-- Mastercard -->
						<img
							v-else-if="card.details.cardLabel == 'Mastercard'"
							src="../../assets/images/MasterCardLogo.png"
							class="YQ4gaf zr758c wA1Bge"
							height="34"
							width="56"
							alt=""
							data-csiid="13"
							data-atf="1"
						/>
						<!-- American Express -->
						<img
							v-else="card.details.cardLabel == 'American Express'"
							src="../../assets/images/ExpressCardLogo.png"
							class="YQ4gaf zr758c wA1Bge"
							height="56"
							width="56"
							alt=""
							data-csiid="14"
							data-atf="1"
						/>
					</div>
					<div class="card-details">
						<div class="card-number">
							{{ card.details.cardLabel }} ••••
							{{ card.details.cardNumber }}
						</div>
						<div class="expires">{{ $t('profile.paymentMethods.expires') }} {{ card.details.cardExpiryDate }}</div>
						<div v-if="card.default == true" class="default-text">{{ $t('profile.paymentMethods.default') }}</div>
						<div v-if="card.default == false" class="default-text" style="visibility: hidden">
							{{ $t('profile.paymentMethods.default') }}
						</div>
						<input
							id="html"
							class="radio"
							:checked="card.default"
							type="radio"
							name="default"
							value="1"
							@click="setDefaultCard(card.customerId, card.id)"
						/>
						<!--          <div-->
						<!--            v-if="card.default == false"-->
						<!--            class="ellipsis-icon"-->
						<!--            @click="toggleDropdown(index)"-->
						<!--          >-->
						<!--            &#8942;-->
						<!--          </div>-->
						<!--          <div-->
						<!--            v-if="card.default == false && isDropdownVisible(index)"-->
						<!--            class="dropdown"-->
						<!--          >-->
						<!--            <div-->
						<!--              class="dropdown-item"-->
						<!--              @click="deleteCard(card.customerId, card.id)"-->
						<!--            >-->
						<!--              Delete-->
						<!--            </div>-->
						<!--            <div-->
						<!--              class="dropdown-item"-->
						<!--              @click="setDefaultCard(card.customerId, card.id)"-->
						<!--            >-->
						<!--              Set as Default-->
						<!--            </div>-->
						<!--          </div>-->
					</div>
				</div>
				<div
					v-if="view != 'profile' && card.default != true && creditCards.length > 1"
					class="delete-card"
					@click="deleteCard(card.customerId, card.id)"
				>
					{{ $t('profile.paymentMethods.deletePaymentMethodButton') }}
				</div>
				<div class="delete-card-none"></div>
			</div>
		</div>
		<!-- Add Payment button -->
		<div v-if="mollieCard == false && view != 'profile'" class="add-payment-button">
			<button variant="primary" @click="addPayment"> 
				<!-- Add Payment Method -->
				{{ $t('profile.paymentMethods.addLabel') }}	
			</button>
		</div>
		<div v-if="mollieCard == true && view != 'profile'" class="add-payment-button">
			<button variant="primary" :class="delayCancel==true?'disabled':''" @click="addPayment" :disabled="delayCancel"> {{ $t('general.cancelLabel') }} </button>
		</div>
		<MollieStep2
			v-if="mollieCard"
			:dataFromParent="parentData"
			:backgroundColor="parentBackgroundColor"
		/>
		<SessionModal :showModal="showModal" @close="closeModal" @confirm="confirmAction">
			<template #title>
				<h1 style="text-align: center">
					<!-- {{ modalTitle }} -->
					{{ $t('profile.paymentMethods.deletePaymentMethodTitle') }}
				</h1>
			</template>
			<template #content>
				<p>
					<!-- {{ modalDescription }} -->
					{{ $t('profile.paymentMethods.deletePaymentMethodDescription') }}
				</p>
				<!-- <div>
					<div class="alert-message">
						<img
							src="../../assets//images/session//icon_warning_black.svg"
							alt="Add Circle Icon"
							class="icon-svg alert-img"
						/>
						<span class="alert" style="text-align: center">{{ modalAlertText }}</span>
					</div>
				</div> -->
			</template>
			<template #confirmButton>
				<button @click="confirmAction">
					{{ $t('profile.paymentMethods.deletePaymentMethodButton') }}
				</button>
			</template>
			<!-- <template #cancelButton>
				<a class="cancel" @click="closeModal">Annuler</a>
			</template> -->
		</SessionModal>
	</div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import graphqlClient from '@/api/db'
import gql from 'graphql-tag'
import Loading from '@/components/Loading.vue'
import MollieStep2 from '../subscription/MollieStep2.vue'
import SessionModal from '@/components/SessionModal.vue'

export default {
	name: 'PaymentMethod',
	components: {
		SessionModal,
		Loading,
		MollieStep2,
	},
	props: {
		id: {
			type: Number,
		},
		view: {
			type: String,
		},
	},

	data() {
		return {
			creditCards: [],
			mollieCard: false,
			delayCancel: false,
			parentData: 'sub-addpayment-method',
			activeDropdownIndex: -1,
			parentBackgroundColor: '#ffd022',
			dropOpen: false,
			showModal: false,
			modalData: {
				customerId: null,
				mandateId: null,
			},
			modalTitle: '',
			modalDescription: '',
			modalAlertText: '',
		}
	},
	computed: {
		...mapState({
			loading: (state) => state.profile.loading,
		}),
	},
	watch: {
		dropOpen(dropOpen) {
			if (dropOpen) {
				document.addEventListener('click', this.toggleDropdownOpened)
			}
		},
	},
	mounted() {
		this.getPaymentMethods()
		console.log('view', this.view)
	},
	methods: {
		closeModal() {
			this.showModal = false
		},
		confirmAction() {
			this.confirmDelete()
		},
		addPayment() {
			if(!this.mollieCard) {
				this.delayCancel = true
				setTimeout(()=>{

					this.delayCancel = false
				},1500)
			}
			this.mollieCard = !this.mollieCard
		},
		...mapActions('profile', {
			getPaymentMethod: 'getPaymentMethod',
			deletePaymentMethod: 'deletePaymentMethod',
			defaultPaymentMethod: 'defaultPaymentMethod',
		}),
		...mapMutations('profile', {
			setLoading: 'setLoading',
		}),
		async getPaymentMethods() {
			const Id = this.id
			const data = await this.getPaymentMethod(Id)

			this.creditCards = data
			this.setLoading(false)
		},
		async deleteCard(customerId, mandateId) {
			// above is defined in json languages.
			// this.modalTitle = this.$t('profile.paymentMethods.deletePaymentMethodTitle')
			// this.modalDescription =this.$t('profile.paymentMethods.deletePaymentMethodDescription')
			this.modalData = {
				customerId,
				mandateId,
			}
			this.showModal = true
		},
		async confirmDelete() {
			if (this.creditCards.length === 1) {
				return 0
			}

			const data = await this.deletePaymentMethod({
				customerId: this.modalData.customerId,
				mandateId: this.modalData.mandateId,
			})

			this.creditCards = data
			this.setLoading(false)
			this.modalData = {
				customerId: null,
				mandateId: null,
			}
			this.showModal = false
		},
		async setDefaultCard(customerId, mandateId) {
			if (this.creditCards.length === 1) {
				return 0
			}

			const data = await this.defaultPaymentMethod({ customerId, mandateId })

			this.creditCards = data
			this.setLoading(false)
		},
		toggleDropdown(index) {
			this.activeDropdownIndex = this.activeDropdownIndex === index ? -1 : index
			this.dropOpen = true
		},
		toggleDropdownOpened(e) {
			if (e.target.className !== 'ellipsis-icon') {
				this.dropOpen = false
				this.activeDropdownIndex = -1
			}
		},

		isDropdownVisible(index) {
			return this.activeDropdownIndex === index
		},
	},
}
</script>

<style scoped>
.payments {
	font-family: 'Poppins Regular';
}
.credit-card {
	display: flex;
	align-items: center;
	background-color: #fff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	position: relative;
	border: 1px solid #fcf3d2;
	padding: 2px 8px 0px 6px;
	justify-content: center;
}

.credit-card-profile {
	display: flex;
	flex-direction: column;
	background-color: #fff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	position: relative;
	border: 1px solid #fcf3d2;
	padding: 16px;
	margin-bottom: 8px;
}

.credit-card-profile .card-icon {
	display: flex;
	gap: 8px;
	margin: 0px;
}

.credit-card-profile .card-icon .card-number {
	font-size: 16px;
	line-height: 25px;
	color: #3c3c3b;
	font-family: 'Poppins Bold';
}

.credit-card-profile .card-details {
	margin-top: 10px;
	display: flex;
}

.credit-card-profile .card-details .delete-card {
	padding: 0px;
}

.card-icon {
	margin-right: 10px;
	margin-top: 5px;
	font-size: 24px;
	color: #4caf50;
	margin-left: 10px;
}

.card-details {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 1;
}
.card-details-box {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex: 1;
	gap: 6px;
}
.card-number {
	font-size: 16px;
	margin-right: 10px; /* Adjust as needed */
	font-weight: bold;
}

.expires {
	font-size: 12px;
	font-family: 'Poppins Regular';
	font-weight: normal;
}
.delete-card {
	color: #3c3c3b;
	font-size: 16px;
	padding: 10px;
	cursor: pointer;
	text-decoration: underline;
	text-align: right;
	font-family: 'Poppins Bold';
}
.delete-card-none {
	padding: 10px;
}
.delete-button {
	color: red;
	font-size: x-large;
	margin-right: 17px;
}
.default-text {
	font-size: 12px;
	color: #898780;
	font-family: 'Poppins Bold';
}
.radio {
	border: 0px;
	width: 16px;
	height: 16px;
	margin-top: -2px;
	accent-color: #3c3c3b;
	cursor: pointer;
}
.add-payment-button button{
	background-color: #258ac9;
	color: #fff;
	border: none;
	padding: 10px 20px;
	font-size: 14px;
	cursor: pointer;
	transition: background-color 0.3s;
	font-family: 'Poppins Regular';
	&.disabled{
		background-color: grey;
		color: #fff;
		touch-action: none;
	}
}

.add-payment-button button:hover {
	background-color: #b4b4d3;
}
.card {
	position: relative;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	padding: 20px;
	width: 300px;
}

.ellipsis-icon {
	cursor: pointer;
	margin-right: 10px;
	font-size: 30px;
}
.ellipsis-icon-visible-no {
	cursor: pointer;
	margin-right: 10px;
	font-size: 30px;
	visibility: hidden;
}

.dropdown {
	position: absolute;
	top: 30px;
	right: 0;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	z-index: 1;
}

.dropdown-item {
	padding: 10px;
	cursor: pointer;
}

.dropdown-item:hover {
	background-color: #f2f2f2;
}

.ellipsis-icon.active + .dropdown {
	display: block;
}
</style>
