<template>
	<div class="qr-code-container">
		<!-- Create a wrapper for the QR code and image -->
		<QRCode :text="text" />
		<img
			v-if="imageSettings.src"
			:src="imageSettings.src"
			class="qr-logo"
			:style="{
				width: imageSettings.width + 'px',
				height: imageSettings.height + 'px',
				position: 'absolute',
				top: '50%',
				left: '51%',
				transform: 'translate(-50%, -50%)',
				background: '#fff',
				zIndex: 1,
			}"
		/>
	</div>
</template>

<script>
import VueQRCodeComponent from 'vue-qrcode-component'
import EnterExitDoor from '../assets/images/entry_exit.svg'
import EnterDoor from '../assets/images/entry.svg'
import EnxitDoor from '../assets/images/exit.svg'
import EnterDoorNight from '../assets/images/entry_night.svg'
import EnxitDoorNight from '../assets/images/exit_night.svg'

export default {
	name: 'QrCode',
	components: {
		QRCode: VueQRCodeComponent,
	},
	props: {
		text: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			imageSettings: {
				src: this.getImage(), // Ensure this is a valid image URL
				width: 60, // Width of the logo image
				height: 60, // Height of the logo image
				excavate: true, // Excavating the QR code for logo space is optional
			},
			qrOptions: {
				size: 256, // Size of the QR code
				// Other options can be added as needed (e.g., error correction, margin)
			},
		}
	},
	methods: {
		getImage() {
			if (this.$router.history.current.name == 'MainDoorQr') {
				return EnterDoorNight
			} else if (this.$router.history.current.name == 'EnteringQr') {
				return EnterDoor
			} else if (this.$router.history.current.name == 'SessionStartQr') {
				return EnterExitDoor
			} else if (this.$router.history.current.name == 'MainDoorExtQr') {
				return EnxitDoorNight
			} else if (this.$router.history.current.name == 'SessionEndQr') {
				return EnxitDoor
			}
		},
	},
	mounted() {
		this.getImage()
	},
	watch: {
		text() {
			this.getImage()
		},
	},
}
</script>

<style scoped>
.qr-code-container {
	position: relative;
	display: inline-block;
}

.qr-logo {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 10; /* Ensure the logo is above the QR code */
}
</style>
