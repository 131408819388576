<template>
	<div class="main">
		<h1 class="h1">{{ $t('sessionParking.payment.title') }}</h1>

		<div
			v-for="(mandate, index) in mandates"
			:key="index"
			:class="{ 'mandate-item': true, 'default-mandate': mandate.id === selectedMandateId }"
		>
			<label class="mandate-label">
				<input type="radio" name="payment_method" v-model="selectedMandateId" :value="mandate.id" />
				<span v-if="displayDefault(index, mandate.id)">{{ $t('general.default') }}</span>
			</label>
			<div class="card-details">
				<img
					:src="getIconSource(mandate.details.cardLabel)"
					:alt="'Card Icon - ' + mandate.details.cardLabel"
					class="icon-svg"
				/>
				<div>
					<p>**** **** **** {{ mandate.details.cardNumber }}
						<br/> <span class="expires">Expires {{ mandate.details.cardExpiryDate }}</span>
					</p>
				</div>
			</div>
		</div>

		<p @click="handleClick" class="add-payment-method">
			{{ $t('sessionParking.payment.modify.paymentMethodText') }}
		</p>

		<div class="start-session">
			<button @click="startSession">{{ $t('sessionParking.payment.modify.ctaText') }}</button>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
	name: 'modifier',
	props: {
		mandates: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			selectedMandateId: null,
		}
	},
	computed: {
		...mapState({
			token: (state) => state.session.token,
		}),
	},
	methods: {
		...mapActions({
			getAllMandates: 'session/getAllMandates',
		}),
		handleClick() {
			this.$emit('new-method')
		},
		getIconSource(cardLabel) {
			// Return appropriate icon source based on card label
			return require(`@/assets/images/session/icon_payment_${cardLabel.toLowerCase().replace(' ', '_')}.svg`)
		},
		displayDefault(index, mandate) {
			const defaultMandate = this.mandates.find(m => m.default)
			if (!this.token) {
				if (defaultMandate) {
					return defaultMandate.id === mandate
				}
				return index === 0
			} else {
				return this.token === mandate
			}
		},
		startSession() {
			this.$store.commit('session/setToken', this.selectedMandateId)
			this.$emit('radio-selected', this.selectedMandateId)
		},
	},
	async beforeMount() {
		// Accessing props before mounting the component
		this.mandates.length || await this.getAllMandates()
		const defaultMandate = this.mandates.find(m => m.default)
		this.selectedMandateId = this.token || defaultMandate?.id || (this.mandates.length? this.mandates[0].id : null)
	},
}
</script>

<style lang="scss" scoped>
.main {
	display: flex;
	flex-direction: column;
	padding: 15px;
	width: 25%;
	margin-left: auto;
	margin-right: auto;

	@media (max-width: 768px) {
		width: 20rem;
	}
}

.h1 {
	color: #1b388a;
	text-align: center;
}

.mandate-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid #fcf3d2;
	padding: 10px;
	margin-bottom: 10px;

	.mandate-label {
		display: flex;
		align-items: center;
	}

	input[type='radio'] {
		margin-right: 10px;
	}

	.card-details {
		display: flex;
		align-items: center;
		text-align: end;
	
		.icon-svg {
			align-self: baseline;
			padding-top: 11px;
		}
	
		.expires {
			font-size: 12px;
		}
	}
}

.default-mandate {
	border-color: #ffd023; /* Add color to the border of the default mandate item */
}

.add-payment-method {
	text-decoration: underline;
	color: red;
	cursor: pointer;
	margin-top: 1rem;
}

.start-session {
	display: flex;
	justify-content: center;
	margin-top: 2rem;

	button {
		padding: 10px 20px;
		background-color: #e75131;
		border: none;
		color: white;
		text-align: center;
		text-decoration: none;
		display: inline-block;
		justify-content: center;
		font-size: 16px;
		width: 100%;
		cursor: pointer;
		border-radius: 5px;
		box-shadow: -5px 5px 0px 0px rgba(182, 175, 175, 0.5);
	}
}
</style>
