<template>
	<div class="profile-addresses">
		<SessionModal
			:showModal="deleteAddressModalOpen"
			@close="toggleDeleteAddressModal({ open: false })"
			@confirm="doRemoveAddress"
		>
			<template v-slot:title>
				<h1>{{ localizedData.deleteAddressTitle }}</h1>
			</template>
			<template v-slot:content>
				<p>
					{{ localizedData.deleteAddressDescription }}
				</p>
			</template>
			<template v-slot:confirmButton>
				<button @click="doRemoveAddress($event, addressDeleteId)">
					{{ localizedData.deleteAddressButton }}
				</button>
			</template>
		</SessionModal>
		<h1 v-if="addressOpen" class="profile-edit-account-title title">
			{{ $t('profile.address.addAddressLabel') }}
		</h1>
		<Accordion v-if="!addressOpen" :title="$t('profile.titleAddresses')" :isOpen="true">
			<!-- <div class="profile-title-section">
				 <h1 class="title profile-title">
					{{ $t('profile.titleAddresses') }}
				</h1> 
				<button
					v-if="!addressOpen && (profile.addresses || []).length < 3"
					class="button blue profile-addresses-new"
					@mousedown="toggleAddress($event)"
					@keyup.enter="toggleAddress($event)"
				>
					{{ $t('general.addLabel') }}
				</button>
			</div>
			-->
			<div class="profile-addresses-content">
				<Loading v-if="isLoading" />
				<p v-if="(profile.addresses || []).length <= 0" class="text error">
					{{ $t('profile.address.noAddress') }}
				</p>
				<div v-else-if="!addressOpen" class="profile-addresses-list">
					<div
						v-for="(address, index) in getAdresses()"
						:key="index"
						class="profile-addresses-item"
					>
						<h3 class="profile-field-title">
							{{ $t(`profile.address.addressTypes.${address.addressType}`) }}
						</h3>
						<div class="profile-input-action-box">
							<div class="profile-input-action-box-info">
								<p class="profile-addresses-item-address">
									{{ addressLine(address) }}
								</p>
								<!-- <div
									:class="{ active: addressOpen && localAddress.id }"
									class="button blue activatable edit profile-addresses-button-edit"
									tabindex="0"
									@mouseup="toggleAddress($event, address.id)"
									@keyup.enter="toggleAddress($event, address.id)"
								/> 
								<div
									v-if="address.addressType !== 'home'"
									class="button-delete profile-addresses-button-delete"
									tabindex="0"
									@mousedown="toggleDeleteAddressModal({ open: true, id: address.id })"
									@keyup.enter="toggleDeleteAddressModal({ open: true, id: address.id })"
								/>
								-->
								<!-- @mousedown="doRemoveAddress($event, address.id)"
								@keyup.enter="doRemoveAddress($event, address.id)" -->
							</div>
						</div>
						<div class="profile-addresses-action">
							<div
								:class="{ active: addressOpen && localAddress.id }"
								class="profile-addresses-action-modify"
								tabindex="0"
								@mouseup="toggleAddress($event, address.id)"
								@keyup.enter="toggleAddress($event, address.id)"
							>
								{{ $t(`profile.address.editAddressButton`) }}
							</div>
							<div
								v-if="address.addressType !== 'home'"
								class="profile-addresses-action-delete"
								tabindex="0"
								@mouseup="toggleDeleteAddressModal({ open: true, id: address.id })"
								@keyup.enter="toggleDeleteAddressModal({ open: true, id: address.id })"
							>
								{{ $t(`profile.address.deleteAddressButton`) }}
							</div>
						</div>
					</div>
				</div>
				<button
					v-if="!addressOpen && (profile.addresses || []).length < 3"
					class="button red profile-addresses-new"
					@mousedown="toggleAddress($event, 0)"
					@keyup.enter="toggleAddress($event, 0)"
				>
					{{ $t('profile.address.add') }}
				</button>
			</div>
		</Accordion>

		<form
			v-else
			:class="{ closed: !addressOpen }"
			class="profile-addresses-form"
			data-vv-scope="addresses-form"
			@submit.prevent="validateFormBeforeSubmit"
		>
			<div>
				<!-- <div v-if="!Boolean(localAddress.id)" class="profile-input-field-box">
					<label for="addressType" class="profile-input-field-label">
						*{{ $t('profile.address.addressTypePlaceholder') }}
					</label>
					<Dropdown
						:value="localAddress.addressType"
						:options="availableAddressTypes"
						:selected="findAddressType(localAddress)"
						:placeholder="$t('profile.address.addressTypePlaceholder')"
						:class="{ error: addressTypeError }"
						name="addressType"
						class="profile-input-field"
						@on-click="(option) => selectAddress(option)"
					/>
				</div> -->
				<div v-if="!Boolean(localAddress.id)" class="profile-addresses-radio">
					<div
						class="profile-addresses-radio-group"
						v-for="(address, index) in availableAddressTypes"
						:key="index"
					>
						<input
							class="profile-addresses-radio-group-input"
							:class="{ error: addressTypeError }"
							type="radio"
							name="addressType"
							:value="localAddress.addressType"
							:checked="index === 0"
							@change="selectAddress(address)"
						/>
						<!-- :checked="findAddressType(localAddress) === address" -->
						<span class="custom-radio"></span>
						<label :for="address.key" class="profile-addresses-radio-group-label">
							{{ address.value }}
						</label>
					</div>
				</div>
				<div class="profile-input-field-box">
					<label for="addressType" class="profile-input-field-label autocomplete-label">
						<span class="label-text">*{{$t('profile.address.addressSelector')}} </span>
						<span v-if="isLoadingGeocode" class="loading-animation"></span>
					</label>
					<autocomplete
						:url="url"
						anchor="street"
						:onSelect="getData"
						:onBeforeAjax="startLoading"
						:onAjaxLoaded="stopLoading"
						:debounce="1300"
						:placeholder="$t('profile.address.addressSelector')"
						:class="{ error: errors.has('addresses-form.streetName') }"
						:data-vv-as="$t('profile.address.addressSelector')"
						inputClass="input-field profile-input-field"
						:customParams="customParams"
						param="q"
						:initValue="structuredAddress(localAddress)"
					></autocomplete>
				</div>
				<div class="profile-input-field-box" v-if="needRealAddress">
					<label for="addressType" class="profile-input-field-label" :style="{display:'flex'}" @mousedown="toggleTooltip()" 
							@keyup.enter="toggleTooltip()"
							@keydown.space.prevent="toggleTooltip()">
						*{{ $t('profile.address.realAddress') }}
						<div :style="{position:'relative',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}">
							<img src="../../assets/images/info-circle.svg" 
							class="icon-info" 
							alt="Info icon"
							tabindex="0"
							aria-label="Toggle tooltip"
							:style="{paddingLeft:'3px'}"
							/>
							<div v-if="showRealAddressTooltip" class="tooltip-box" role="tooltip">
								{{ $t('profile.address.realAddressTooltip') }}
							</div>
						</div>
					</label>
					<input
							v-model="localAddress.realAddress"
							v-validate="{ required: true}"
							:placeholder="$t('profile.address.realAddress')"
							:class="{ error: errors.has('addresses-form.realAddress') }"
							:data-vv-as="$t('profile.address.realAddress')"
							name="realAddress"
							type="text"
							class="input-field inline profile-input-field"
							autocomplete="address-line1"
							v-on:keyup="updateValue('realAddress', $event)"
						/>
				</div><div class="profile-input-field-box">
					<label for="addressType" class="profile-input-field-label">
						*{{
							localAddress.addressType === 'poi'
								? $t('profile.address.streetNamePoi')
								: $t('profile.address.streetName')
						}}
					</label>
					<input
							v-model="localAddress.streetName"
							v-validate="{ required: true}"
							:placeholder="$t('profile.address.streetName')"
							:class="{ error: errors.has('addresses-form.streetName') }"
							:data-vv-as="$t('profile.address.streetName')"
							name="streetName"
							type="text"
							class="input-field inline profile-input-field disabled"
							autocomplete="address-line1"
							v-on:keyup="updateValue('streetName', $event)"
							readonly
						/>
				</div>
				<div class="profile-addresses-numbers">
					<div class="profile-input-field-box">
						<label for="addressType" class="profile-input-field-label">
							*{{
								localAddress.addressType === 'poi'
									? $t('profile.address.houseNumberPoi')
									: $t('profile.address.houseNumber')
							}}
						</label>
						<input
							v-model="localAddress.streetNumber"
							v-validate="{ required: true, regex: /\d/ }"
							:placeholder="$t('profile.address.houseNumber')"
							:class="{ error: errors.has('addresses-form.streetNumber') }"
							:data-vv-as="$t('profile.address.houseNumber')"
							name="streetNumber"
							type="text"
							class="input-field inline profile-input-field disabled"
							autocomplete="address-line2"
							v-on:keyup="updateValue('streetNumber', $event)"
							readonly
						/>
					</div>
					<div class="profile-input-field-box">
						<label for="addressType" class="profile-input-field-label">
							{{ $t('profile.address.busNumber') }}
						</label>
						<input
							v-model="localAddress.busNumber"
							v-validate="{ regex: /[^a-zA-Z]/ }"
							:placeholder="$t('profile.address.busNumber')"
							:class="{ error: errors.has('addresses-form.bus') }"
							:data-vv-as="$t('profile.address.busNumber')"
							name="bus"
							type="text"
							class="input-field inline profile-input-field disabled"
							autocomplete="address-line3"
							readonly
						/>
					</div>
				</div>
				<div class="profile-input-field-box">
					<label for="addressType" class="profile-input-field-label">
						*{{ $t('profile.address.postalCode') }}
					</label>
					<input
						v-model="localAddress.zipCode"
						v-validate="'required|length:4'"
						:placeholder="$t('profile.address.postalCode')"
						:class="{ error: errors.has('addresses-form.zipCode') }"
						:data-vv-as="$t('profile.address.postalCode')"
						name="zipCode"
						type="text"
						class="input-field profile-input-field disabled"
						v-on:keyup="updateValue('postalCode', $event)"
						readonly
					/>
				</div>
				<div class="profile-input-field-box">
					<label for="addressType" class="profile-input-field-label">
						*{{ $t('profile.address.city') }}
					</label>
					<input
						v-model="localAddress.city"
						v-validate="'required'"
						:placeholder="$t('profile.address.city')"
						:class="{ error: errors.has('addresses-form.city') }"
						:data-vv-as="$t('profile.address.city')"
						name="city"
						type="text"
						class="input-field profile-input-field disabled"
						v-on:keyup="updateValue('city', $event)"
						readonly
					/>
				</div>
				<div class="profile-addresses-form-actions">
					<!-- <button class="button blue active" type="button" @click="toggleAddress($event, 0)">
						{{ $t('general.cancelLabel') }}
					</button> -->
					<button
						:disabled="errors.any('addresses-form') || addressTypeError"
						:class="{ disabled: errors.any('addresses-form') || addressTypeError }"
						class="button red"
						type="submit"
					>
						{{
							doesNumberExists(localAddress.id) ? $t('general.editLabel') : $t('general.addLabel')
						}}
					</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import { isEmpty, isNumber, sortBy } from 'lodash/fp'
import { mapActions, mapState, mapGetters } from 'vuex'
import { tryString } from '@/helpers'
import Vue from 'vue'
import Loading from '@/components/Loading.vue'
import Dropdown from '@/components/form/Dropdown.vue'
import Autocomplete from './vue-autocomplete.vue'
import SessionModal from '../SessionModal.vue'
import Accordion from '@/components/Accordion.vue'
require('vue2-autocomplete-js/dist/style/vue2-autocomplete.css')

// import { logger } from '@/logger'

export default {
	name: 'ProfileAddresses',
	components: {
		Loading,
		Dropdown,
		Autocomplete,
		SessionModal,
		Accordion,
	},
	props: {
		addressOpen: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			url: 'https://geocoding.agifly.cloud/search',
			localAddress: {
				postalCode: '',
				city: '',
				busNumber: '',
				houseNumber: '',
				streetName: '',
				addressType: '',
				zipCode: '',
				streetNumber: '',
				realAddress: null,
				latitude: null,
				longitude: null,
			},
			customParams: {
				addressdetails: 1,
				namedetails: 1,
				format: 'geojson',
				'accept-language': 'en-EN',
				countrycodes: 'be',
				// polygon_geojson:1
			},
			addressTypeError: false,
			deleteAddressModalOpen: false,
			addressDeleteId: null,
			profile: {},
			needRealAddress: false,
			isLoadingGeocode: false,
			showRealAddressTooltip:false,
		}
	},
	computed: {
		localizedData() {
			return {
				deleteAddressTitle: this.$t('profile.address.deleteAddressTitle'),
				deleteAddressDescription: this.$t('profile.address.deleteAddressDescription'),
				deleteAddressButton: this.$t('profile.address.deleteAddressButton'),
			}
		},
		...mapState('profile', {
			isLoading: (state) => state.loading,
			currentUser: (state) => state.currentUser,
			userInAdmin: (state) => state.userInAdmin,
			isAdminEdit: (state) => state.isAdminEdit,
			activeAddressId: (state) => state.activeAddressId,
		}),
		...mapGetters('profile', {
			availableAddressTypes: 'availableAddressTypes',
			availableAddressType: 'availableAddressType',
			parkingRequested: 'parkingRequested',
			addressTypes: 'addressTypes',
		}),
	},
	mounted() {
		this.$validator.pause()
		this.profile = this.isAdminEdit ? this.userInAdmin : this.currentUser
		if (this.activeAddressId > 0) {
			this.toggleAddress('', this.activeAddressId)
		}else{
			this.selectAddress(this.availableAddressType)
		}
		// console.log('addressTypeError', this.addressTypeError)
		// console.log(this.availableAddressType, 'availableAddressType')
	},
	watch: {
		currentUser(newVal) {
			this.profile = this.isAdminEdit ? this.userInAdmin : this.currentUser
		},
		localAddress(newVal) {
			// console.log('newVal', newVal, 'erros', this.errors)
		},
	},
	methods: {
		...mapActions({
			updateAddress: 'profile/updateAddress',
			createParkingRequest: 'profile/createParkingRequest',
		}),
		getData(data) {
			Vue.set(this.localAddress, 'zipCode', data?.properties?.address?.postcode)
			Vue.set(this.localAddress, 'streetName', data?.properties?.address?.road)
			Vue.set(
				this.localAddress,
				'streetNumber',
				data?.properties?.address?.house_number || data?.properties?.address?.building || '0'
			)
			Vue.set(
				this.localAddress,
				'city',
				data?.properties?.address?.municipality ||
					data?.properties?.address?.city ||
					data?.properties?.address?.town ||
					data?.properties?.address?.village
			)
			Vue.set(this.localAddress, 'latitude', data?.geometry?.coordinates[1])
			Vue.set(this.localAddress, 'longitude', data?.geometry?.coordinates[0])
			Vue.set(this.localAddress, 'realAddress', data?.nearByAddress || null)
			this.needRealAddress = !!data?.nearByAddress
			this.localAddress = {...this.localAddress}
			return data
		},
		findAddressType(address) {
			return this.addressTypes.find((l) => l.key === address.addressType) || {}
		},
		selectAddress(option) {
			this.addressTypeError = false
			// console.log('Selected address: ', option)
			return Vue.set(this.localAddress, 'addressType', option.key)
		},
		isObjectEmpty(obj) {
			return isEmpty(obj)
		},
		doesNumberExists(number) {
			return isNumber(number)
		},
		addressLine(address) {
			return `${address.streetName}, ${address.city}`
		},
		/* eslint-disable-next-line complexity, max-statements */
		toggleAddress(e, id) {
			// console.log('id', id)
			if (e) {
				e.preventDefault()
			}

			if (id > 0) {
				this.$store.commit('profile/setActiveAddressId', id)
			} else {
				this.$store.commit('profile/setActiveAddressId', 0)
			}
			if (this.activeAddressId > 0) {
				this.localAddress = Object.assign(
					{},
					this.profile.addresses.find((address) => address.id === id)
				)
				this.localAddress.zipCode = this.localAddress.postalCode
				this.localAddress.streetNumber = this.localAddress.houseNumber
				this.needRealAddress = this.localAddress.realAddress || null
			}
			if (e.which === 1 || e.button === 0) {
				const closing = this.addressOpen

				this.$emit('toggleAddressForm')

				if (!closing && isNumber(id)) {
					if (this.profile.addresses) {
						this.localAddress = Object.assign(
							{},
							this.profile.addresses.find((address) => address.id === id)
						)
						this.localAddress.zipCode = this.localAddress.postalCode
						this.localAddress.streetNumber = this.localAddress.houseNumber
					} else {
						this.localAddress = {}
					}
				} else {
					this.localAddress = {}
				}

				if (closing) {
					this.$validator.reset('addresses-form')
					this.errors.clear()
					this.addressTypeError = false
				}
			}
		},
		doUpdateAddress() {
			const address = {
				...this.localAddress,
				postalCode: this.localAddress.zipCode,
				houseNumber: this.localAddress.streetNumber,
			}

			delete address.zipCode
			delete address.streetNumber
			// console.log('address:', address)
			this.updateAddress(address)
		},
		doRemoveAddress(e, id) {
			if (e) {
				e.preventDefault()
			}

			// only left-click
			if (e.which === 1 || e.button === 0) {
				if (this.profile.requests.some((request) => request.addressId === id)) {
					this.$emit('toggleDeleteModal', id)
					this.toggleDeleteAddressModal({ open: false })
				} else {
					this.$emit('removeAddress', id)
					this.toggleDeleteAddressModal({ open: false })
				}
			}
		},
		validateFormBeforeSubmit() {
			if (this.$validator._paused) {
				// console.log('resuming validation after submission')
				this.$validator.resume()
			}

			this.addressTypeError = !this.localAddress.addressType
			this.$validator.validateAll('addresses-form').then((result) => {
				// console.log('Validation result: ', result, !this.addressTypeError)
				if (result && !this.addressTypeError) {
					this.$validator.pause()
					this.doUpdateAddress()

					// this.addressOpen = false
					this.$emit('toggleAddressForm')
				}
			})
		},
		getAdresses() {
			return sortBy('addressType', this.profile.addresses)
		},
		populateAutocompletedAddress(autocompletedAddress) {
			// Here you assign the autocompletedAddress to your address data.
			logger.debug('Autocompleted address before updating other fields: ' + autocompletedAddress)
			this.localAddress = { ...this.localAddress, ...autocompletedAddress }
		},
		toggleDeleteAddressModal(option = {}) {
			if ('open' in option) {
				this.deleteAddressModalOpen = option.open
				this.addressDeleteId = option.id
			} else {
				this.deleteAddressModalOpen = !this.deleteAddressModalOpen
				this.addressDeleteId = null
			}
		},
		updateValue(name, e) {
			this.localAddress[name] = e.target.value
			this.$validator.reset('addresses-form')
			console.log('addressTypeError', this.addressTypeError, 'errors', this.errors)
		},
		structuredAddress(address) {
			let addressString = tryString(address.streetName)
			if(address.streetNumber!=='0') addressString += tryString(address.streetNumber, { prefix: addressString.trim() ? ' ' : '' })
			addressString += tryString(address.busNumber, { prefix: addressString.trim() ? ' ' : '' })
			addressString += tryString(address.postalCode, { prefix: addressString.trim() ? ', ' : '' })
			addressString += tryString(address.city, { prefix: address.postalCode ? ' ' : ', ' })
			return addressString;
		},
		tryString,
		startLoading(e) {
			this.isLoadingGeocode = true
		},
		stopLoading(e) {
			this.isLoadingGeocode = false
		},
		toggleTooltip() {
      this.showRealAddressTooltip = !this.showRealAddressTooltip;
    },
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/profile/index';
.icon-info{
	width: 12px
}

.autocomplete-label {
	height: 20px;
	.label-text {
		float: left
	}
	.loading-animation{
		margin-left: 2px;
	}
}

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

.loading-animation {
		position: relative;
		width: 20px;
		height: 20px;
		background-image: url('../../assets/images/wheel.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: 100%;
		animation: rotate 5s infinite linear;
		display: inline-block;
	}

	.tooltip-box{
		position: absolute;
    background: black;
    color: white;
    padding: 8px;
    min-width: 500px;
    top: inherit;
    border-radius: 8px;
		left: 16px;

	}
	@media screen and (max-width: 600px) {
		.tooltip-box {
		min-width: 250px;
  }
}
</style>
