// sessionStop.js

import graphqlClient from '../../api/db';
import gql from 'graphql-tag';
import { extractGraphqlError } from '@/helpers';

// Initial state
const initialState = () => ({
    payment: {},
    isLoading: false,
    error: null,
});

const state = initialState();

// GraphQL mutation for stopping the session
const getPayentDetail = gql`
  query PaymentDetail($paymentId: String!, $subscriptionId: String) {
    paymentDetail(paymentId: $paymentId, subscriptionId: $subscriptionId) {
        id
        status
        details {
            failureMessage
            failureReason
        }
        metadata
        mandateId
    }
  }
`;

// Actions
const actions = {
    async getPayentDetail({ commit, dispatch }, sessionProps) {
        commit('setLoading', true);
        try {
            // Check if there are any records for the given sessionProps
            const queryResponse = await graphqlClient.query({
                query: getPayentDetail,
                variables: { paymentId: sessionProps.paymentId, subscriptionId: sessionProps.subscriptionId},
                fetchPolicy: 'network-only',
            });
            // check if session qr code is more than 1
            if (queryResponse.data.paymentDetail) {
                return queryResponse.data.paymentDetail;
            } else {
                console.log('getPayentDetail', queryResponse.data)                
            }
            return queryResponse.data;
        } catch (error) {
            commit('setError', extractGraphqlError(error.message));
        } finally {
            commit('setLoading', false);
        }
    },
};



// Mutations
const mutations = {
    resetState(state) {
        Object.assign(state, initialState())
    },
    setLoading(state, isLoading) {
        state.isLoading = isLoading;
    },
    setError(state, error) {
        state.error = error;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
