<template>
	<div :class="{ transparent: transparent, opaque: opaque }" class="loading">
		<div :class="positionClass" class="loading-content">
			<div class="loading-animation" />
			<p class="loading-text">{{ loadingText }} <span>.</span><span>.</span><span>.</span></p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Loading',
	props: {
		positionClass: {
			type: String,
			required: false,
			default: '',
		},
		text: {
			type: String,
			required: false,
			default: '',
		},
		transparent: {
			type: Boolean,
			required: false,
			default: false,
		},
		opaque: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	computed: {
		loadingText() {
			return this.text || this.$t('loading.text')
		}
	}
}
</script>
<style scoped lang="scss">
@import '../assets/scss/components/loading';
</style>
