<template>
	<div>
		<div class="pageNotFound">
			<h1>{{ $t('notFoundPage.title') }}</h1>
			<p class="sub-heading">{{ $t('notFoundPage.subTitle') }}</p>
			<img src="../../assets/images/Illustration.svg" alt="Illustration" />
			<div class="info" v-html="$t('notFoundPage.info')"></div>
			<button class="button red" @click="goToPreviousRoute">
				{{ $t('notFoundPage.buttonLabel') }}
			</button>
		</div>
	</div>
</template>

<script>
/* import { logger } from '@/logger' */

export default {
	name: 'Pagenofound',
	methods: {
		goToPreviousRoute() {
			this.$router.go(-1)
		},
	},
}
</script>

<style lang="scss" scoped>
.pageNotFound {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding: 50px 20px 50px 20px;
}
.pageNotFound h1 {
	font-size: 40px;
	line-height: 54px;
	font-family: 'Poppins Bold';
	margin: 5px 0px;
}
.pageNotFound .sub-heading {
	color: #3c3c3b;
	font-size: 24px;
	line-height: 32px;
	font-family: 'Poppins Bold';
	margin: 5px 0px;
	text-align: center;
}
.pageNotFound img {
	margin: 20px 0px;
}
.pageNotFound .info {
	color: #3c3c3b;
	font-size: 16px;
	line-height: 25px;
	font-family: 'Poppins Regular';
	text-align: center;
}
.pageNotFound .button {
	box-shadow: -5px 5px 0 0 #00000029;
	margin-top: 30px;
}
</style>
