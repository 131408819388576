<template>
	<div
		v-if="$router.history.current.name !== 'login' && $router.history.current.name !== 'signup'"
		class="previous-button-main"
	>
		<div
			class="previous-button-main-back-button"
			tabindex="0"
			@click="
				profileEditable
					? toggleEditProfile($event)
					: addressOpen
					? toggleAddressForm($event)
					: createAccountOpen
					? toggleCreateAccountOpen()
					: goToPreviousRoute()
			"
		>
			<!-- : doBackToParentAccount($event) -->
			<!-- Page précédente -->
			{{ $t('general.previousPage') }}
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
	name: 'PreviousButton',
	props: {
		isChild: {
			type: Boolean,
			default: false,
		},
		currentChild: {
			type: Object,
			default: null,
		},
		doBackToParentAccount: {
			type: Function,
		},
		profileEditable: {
			type: Boolean,
			default: false,
		},
		toggleEditProfile: {
			type: Function,
		},
		addressOpen: {
			type: Boolean,
			default: false,
		},
		toggleAddressForm: {
			type: Function,
		},
		createAccountOpen: {
			type: Boolean,
			default: false,
		},
		toggleCreateAccountOpen: {
			type: Function,
		},
	},
	data() {
		return {
			prevRoute: null,
		}
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			vm.prevRoute = from
		})
	},
	computed: {
		...mapState({
			currentUser: (state) => state.profile.currentUser,
		}),
	},
	methods: {
		...mapActions({
			switchAccount: 'profile/switchAccount',
		}),
		doSwitchAccount(id) {
			this.$emit('onSwitchAccount')
			this.switchAccount(id)
		},
		goToPreviousRoute() {
			if (this.$router.history.current.name === 'profile') {
				this.doBackToParentAccount(this.$event)
			} else if (
				this.$router.history.current.name === 'smsValidation' &&
				this.$route.query.from !== undefined
			) {
				if (
					this.currentChild?.parentId === undefined &&
					this.$route.query.from !== '/how-it-pay?parkingId=330' &&
					this.$route.query.from !== '/how-it-pay?parkingId=329'
				) {
					this.$router.push({ name: 'profile' })
				} else if (
					this.$route.query.from === '/how-it-pay?parkingId=330' ||
					this.$route.query.from === '/how-it-pay?parkingId=329'
				) {
					if (this.$route.query.from === '/how-it-pay?parkingId=330') {
						this.$router.push('/parkings?parkingId=330')
					} else if ('/how-it-pay?parkingId=329') {
						this.$router.push('/parkings?parkingId=329')
					} else {
						this.$router.go(-1)
					}
				} else {
					this.$router.go(-2)
					this.doSwitchAccount(this.currentChild.id)
				}
			} else if (this.$router.history.current.name !== 'HowItPay') {
				// Go back to the previous route
				// console.log('go back to the previous route', this.prevRoute)
				this.$router.go(-1)
			} else {
				//everyone define their own method for the event
				this.$emit('previous-step')
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/components/previousButton';
</style>
