<template>
	<div>
		<Loading v-if="loading" />
		<Menu activeMenu="session" />
		<div class="admin-parking">
			<div class="admin-parking-info-header">
				<h2 class="admin-parking-info-header-title">Select a session</h2>
				<form
					class="admin-parking-form admin-session-form"
					data-vv-scope="parking-search-form"
					autocomplete="off"
					@submit.prevent="searchForm()"
				>
					<div class="admin-parking-search-content">
						<div class="admin-parking-search-field">
							<input
								ref="search"
								v-model="query"
								v-validate="{ required: true, min: 2 }"
								:placeholder="$t('Rechercher session (ID, parking)')"
								:class="{ error: errors.has('parking-search-form.search') }"
								name="search"
								type="text"
								class="input-field admin-input-field input admin-search-field"
							/>
							<p v-show="errors.has('parking-search-form.search')" class="input-error">
								{{ errors.first('parking-search-form.search') }}
							</p>
						</div>
						<button
							:disabled="errors.any('parking-search-form')"
							hidden
							:class="{ disabled: errors.any('parking-search-form') }"
							class="button blue admin-parking-search-button"
							type="submit"
						>
							Search
						</button>
					</div>
				</form>
			</div>
			<div class="admin-parking-search">
				<section v-if="filteredSessions.length > 0">
					<div class="admin-item-row admin-parking-item-header" style="border-color: black">
						<div class="admin-item-col medium header">ID</div>
						<div class="admin-item-col medium header">Name</div>
						<div class="admin-item-col small header">N. of Bikes</div>
						<div class="admin-item-col small header">Parking</div>
						<div class="admin-item-col small header">Start Time</div>
						<div class="admin-item-col small header">Duration</div>
						<div class="admin-item-col small header">Action</div>
					</div>
					<SessionResult
						v-for="(p, index) in filteredSessions"
						:key="index"
						:session="p"
						:cancelSession="cancelSession"
					/>
				</section>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import Loading from '@/components/Loading.vue'
import Menu from '@/components/admin/Menu'
import SessionResult from '@/components/admin/session/SessionResult.vue'
import ValidationRequired from '@/mixins/validation-required'
import { filter, find, omit, includes } from 'lodash/fp'
import { bigParkingTypes } from '@/config'

/* import { logger } from '@/logger' */

export default {
	name: 'AdminSession',
	components: {
		Loading,
		Menu,
		SessionResult,
	},
	mixins: [ValidationRequired],
	data() {
		return {
			query: '',
			formOpen: false,
			filteredSessions: [],
			customErrors: {},
			hasCustomError: false,
		}
	},
	computed: {
		...mapState('admin', {
			allSessions: (state) => state.sessions.all,
			loading: (state) => state.loading,
		}),
	},
	mounted() {
		this.$validator.pause()
		this.getSessions({ limit: 50, offset: 0, isActive: true })
	},
	methods: {
		...mapActions('admin', {
			getSessions: 'sessions/getSessions',
		}),
		...mapActions('session', {
			cancelCurrentSession: 'cancelCurrentSession',
		}),
		searchForm() {
			if (!this.query) {
				console.log('if')
				this.filteredSessions = [...this.allSessions]
			} else {
				console.log('else')
				const re = new RegExp(this.query, 'i')

				this.filteredSessions = filter(
					(session) =>
						session.parking &&
						(session.parking.code.search(re) >= 0 ||
							session.sessionId.search(re) >= 0 ||
							`${session.user.firstName} ${session.user.lastName}`.search(re) >= 0),
					this.allSessions
				)
			}
		},
		cancelSession(sessionId) {
			if (confirm('Are you sure you want to cancel this session IMMEDIATELY?')) {
				return this.cancelNowSession(sessionId)
			}
			return false
		},
		async cancelNowSession(sessionId) {
			try {
				const response = await this.cancelCurrentSession(parseInt(sessionId))
				if (response) {
					this.getSessions({ limit: 50, offset: 0, isActive: true })
				}
			} catch (error) {
				console.error('Error fetching session:', error)
			}
		},
	},
	watch: {
		allSessions(newVal) {
			if (newVal) {
				this.filteredSessions = newVal || []
				if (this.query) {
					this.searchForm()
				}
			}
		},
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/pages/adminParking';
@import '../../assets/scss/pages/admin';

.mx-datepicker {
	width: 100%;
}

.mx-input-append {
	width: 35px;
	right: 5px;
}

.admin-session-form {
	width: 75%;
}

.admin-parking-search {
	margin-top: 10px;

	.admin-parking-item-header {
		padding-bottom: 8px;
	}
}
</style>
