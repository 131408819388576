<template>
	<div>
		<!-- <Modal
			v-if="mobibModal"
			:modalOpen="mobibModal"
			:loading="mobibLoading && mobibType === 'modal'"
			@confirm="doUpdateMobib('', 'remove', 'modal')"
			@cancel="toggleMobibModal"
			@close="toggleMobibModal"
		>
			{{ $t('profile.confirmDeleteMobib') }}
		</Modal> -->
		<SessionModal :showModal="mobibModal" @close="toggleMobibModal" @confirm="doUpdateMobib">
			<template #title>
				<h1 style="text-align: center">
					<!-- {{ $t('profile.paymentMethods.deletePaymentMethodTitle') }} -->
					Confirm
				</h1>
			</template>
			<template #content>
				<p>
					<!-- {{ $t('profile.paymentMethods.deletePaymentMethodDescription') }} -->
					{{ $t('profile.confirmDeleteMobib') }}
				</p>
			</template>
			<template #confirmButton>
				<button @click="doUpdateMobib('', 'remove', 'modal')">
					{{ $t('profile.paymentMethods.deletePaymentMethodButton') }}
				</button>
			</template>
		</SessionModal>
		<Modal
			v-if="proposalModal"
			:modalOpen="proposalModal"
			:loading="proposalLoading"
			:cancelLabel="$t('profile.proposal.cancelLabel')"
			:confirmLabel="$t('profile.proposal.confirmLabel')"
			:hideActions="selectedProposal && selectedProposal.status !== 1"
			:decideLater="true"
			:canConfirm="termsOfConditionProposal"
			@confirm="doAcceptProposal"
			@doDecideLater="closeProposalModal"
			@cancel="doRejectProposal"
			@close="closeProposalModal"
		>
			<h3 v-if="selectedProposal.status !== 1">
				{{ $t('profile.proposal.expired') }}
			</h3>
			<div v-else>
				<h3>{{ $t('profile.proposal.info') }}</h3>
				<div class="profile-modal-parking-info">
					<p class="profile-modal-text">
						{{ selectedProposal.parking.name }} ({{ selectedProposal.parking.code }})
					</p>
					<p class="profile-modal-text">
						{{ address(selectedProposal) }}
					</p>
					<p class="profile-modal-text warning">* {{ $t('profile.proposal.attentionText') }}</p>
					<Checkbox
						v-model="termsOfConditionProposal"
						name="termsOfConditionProposal"
						class="profile-modal-checkbox"
					>
						<span slot="label" class="checkbox-text text">
							{{ $t('signup.acceptLabel') }}
							<a :href="$t('general.navigationLinks.terms')" target="_blank" class="link">{{
								$t('general.navigation.terms')
							}}</a>
						</span>
					</Checkbox>
				</div>
				<h3>
					{{
						$t('profile.proposal.acceptQuestion', { validUntil: date(selectedProposal.validUntil) })
					}}
				</h3>
			</div>
		</Modal>
		<Modal
			v-if="confirmProposalModal"
			:modalOpen="confirmProposalModal"
			:loading="mobibLoading && mobibType === 'modal'"
			:cancelLabel="$t('profile.proposal.cancelLabel')"
			:confirmLabel="$t('profile.proposal.confirmLabel')"
			:canClose="true"
			:canConfirm="isDefined(rejectReason.value)"
			:canCancel="isDefined(rejectReason.value)"
			@confirm="confirmRejectProposal(true)"
			@cancel="confirmRejectProposal(false)"
			@close="closeConfirmProposalModal"
		>
			<Dropdown
				:value="rejectReason.value"
				:options="rejectOptions"
				:selected="rejectReason"
				:placeholder="$t('profile.proposal.chooseRejectReason')"
				name="rejectReason"
				class="modal-request-dropdown"
				@on-click="(option) => selectRejectReason(option)"
			/>
			<input
				v-if="rejectReason.key === 'other'"
				v-model="rejectReasonOther"
				:placeholder="$t('profile.proposal.otherRejectReason')"
				name="ohterReason"
				type="text"
				class="input-field profile-input-field input"
			/>
			<h3>
				{{
					$t('profile.proposal.confirmTextModal', {
						addressType: $t(`profile.address.addressTypes.${addressType(selectedProposal)}`),
					})
				}}
			</h3>
		</Modal>
		<!-- <Modal
			v-if="cancelSubModal"
			:modalOpen="cancelSubModal"
			:loading="cancelSubLoading"
			:cancelLabel="$t('profile.subscription.cancelButtonLabel')"
			:confirmLabel="$t('profile.subscription.deleteSubscriptionLabel')"
			:canClose="true"
			@confirm="confirmCancelSub"
			@cancel="closeCancelSubModal"
			@close="closeCancelSubModal"
		>
			<h3>
				{{ $t('profile.subscription.confirmCancelModal') }}
			</h3>
			<p>
				{{ $t('profile.subscription.confirmCancelDisclaimer') }}
			</p>
		</Modal> -->
		<SessionModal
			:showModal="bankAccountModal"
			@close="closeBankAccountModal"
			@confirm="doUpdateBankAccount"
		>
			<template v-slot:title>
				<h1>{{ localizedData.BankAccountModalLabel }}</h1>
			</template>
			<template v-slot:content>
				<p>
					{{ localizedData.confirmBankAccountDisclaimer }}
				</p>
				<div class="profile-input-action-box">
					<label class="profile-field-title"> </label>
					<input
						v-model="currentUser.bankAccount"
						:placeholder="localizedData.BankAccountModalTitle"
						name="bankAccount"
						type="text"
						class="input-field profile-input-field"
					/>
				</div>
			</template>
			<template v-slot:confirmButton>
				<button @click="doUpdateBankAccount()">{{ $t('general.addLabel') }}</button>
			</template>
		</SessionModal>
		<SessionModal
			:showModal="cancelSubModal"
			@close="closeCancelSubModal"
			@confirm="confirmCancelSub"
		>
			<template v-slot:title>
				<h1>{{ localizedData.confirmCancelSubModal }}</h1>
			</template>
			<template v-slot:content>
				<p>
					{{ localizedData.confirmCancelSubDisclaimer }}
				</p>
			</template>
			<template v-slot:confirmButton>
				<button @click="confirmCancelSub">{{ localizedData.deleteSubscriptionLabel }}</button>
			</template>
		</SessionModal>
		<!-- <Modal
			v-if="deleteModalOpen"
			:modalOpen="deleteModalOpen"
			:cancelLabel="$t('profile.proposal.cancelLabel')"
			:confirmLabel="$t('profile.proposal.confirmLabel')"
			:canClose="false"
			@confirm="doConfirmDeleteModal"
			@cancel="doCancelDeleteModal"
		>
			<h3>{{ $t('profile.address.confirmTextModal') }}</h3>
		</Modal> -->
		<SessionModal
			:showModal="deleteModalOpen"
			@close="doCancelDeleteModal"
			@confirm="doConfirmDeleteModal"
		>
			<template v-slot:title> </template>
			<template v-slot:content>
				<!-- <p>
					{{ $t('profile.address.confirmTextModal') }}
				</p> -->
				<div>
					<div style="display: flex">
						<img
							src="../../assets//images/session//icon_warning_black.svg"
							alt="Add Circle Icon"
							class="icon-svg alert-img"
						/>
						<span class="alert">{{ $t('profile.address.confirmTextModal') }}</span>
					</div>
				</div>
			</template>
			<template v-slot:confirmButton>
				<button @click="doConfirmDeleteModal">{{ localizedData.deleteSubscriptionLabel }}</button>
			</template>
		</SessionModal>
		<!-- <Modal
			v-if="deleteAccountModalOpen"
			:loading="deleteAccountModalLoading"
			:modalOpen="deleteAccountModalOpen"
			:cancelLabel="$t('profile.CancelFamilyLabel')"
			:confirmLabel="$t('profile.deleteFamilyLabel')"
			:canClose="false"
			@confirm="doDeleteFamilyAccount"
			@cancel="toggleDeleteAccountModal({ open: false })"
		>
			<h3>{{ $t('profile.deleteFamilyAccountMessage') }}</h3>
				<p>
					{{ $t('profile.deleteFamilyAccountDisclaimer') }}
				</p>
		</Modal> -->
		<SessionModal
			:showModal="deleteAccountModalOpen"
			@close="toggleDeleteAccountModal({ open: false })"
			@confirm="doDeleteFamilyAccount"
		>
			<template v-slot:title>
				<h1>{{ localizedData.deleteFamilyTitle }}</h1>
			</template>
			<template v-slot:content>
				<p>
					{{ localizedData.deleteFamilyAccountDisclaimer }}
				</p>
			</template>
			<template v-slot:confirmButton>
				<button @click="doDeleteFamilyAccount">{{ localizedData.deleteFamilyLabel }}</button>
			</template>
		</SessionModal>

		<!-- <Modal
			v-if="familyAccountInfoOpen"
			:modalOpen="familyAccountInfoOpen"
			:hideCancel="true"
			:confirmLabel="'OK'"
			:canClose="true"
			@confirm="toggleFamilyAccountInfo()"
			@close="toggleFamilyAccountInfo()"
		>
			<h3>{{ $t('profile.completeFamilyProfileMessage') }}</h3>
		</Modal> -->
		<SessionModal
			:showModal="familyAccountInfoOpen"
			@close="toggleFamilyAccountInfo"
			@confirm="toggleFamilyAccountInfo"
		>
			<template v-slot:title>
				<!-- <h1>{{ localizedData.deleteFamilyTitle }}</h1> -->
			</template>
			<template v-slot:content>
				<p>
					{{ $t('profile.completeFamilyProfileMessage') }}
				</p>
			</template>
			<template v-slot:confirmButton>
				<button @click="toggleFamilyAccountInfo">OK</button>
			</template>
		</SessionModal>
		<RequestModal
			v-if="requestModalOpen"
			:loading="false"
			:modalOpen="requestModalOpen"
			:shouldSelectLocation="true"
			:addresses="currentAddresses"
			:activeAddresses="activeAddresses"
			@close="toggleRequestModal"
		/>
		<PreviousButton
			v-if="isChild || profileEditable || createAccountOpen === true || addressOpen === true"
			:isChild="isChild"
			:profileEditable="profileEditable"
			:doBackToParentAccount="doBackToParentAccount"
			:toggleEditProfile="toggleEditProfile"
			:addressOpen="addressOpen"
			:toggleAddressForm="toggleAddressForm"
			:createAccountOpen="createAccountOpen"
			:toggleCreateAccountOpen="toggleCreateAccountOpen"
		/>
		<Loading v-if="!isLoaded" />
		<div v-else :class="{ 'is-child': isChild }" class="profile">
			<div v-if="isAdminEdit" class="profile-admin-block">
				<div
					class="button blue profile-child-account-button back-button"
					tabindex="0"
					@mousedown="doExitAdminEdit($event)"
					@keyup.enter="doExitAdminEdit($event)"
				>
					Exit to admin
				</div>
				<h4 class="profile-admin-title">
					{{ $t('admin.adminEdit') }}
				</h4>
			</div>
			<div v-if="isChild" class="profile-child-account">
				<!-- <div
					class="button blue profile-child-account-button back-button"
					tabindex="0"
					@mousedown="
						profileEditable
							? toggleEditProfile($event)
							: addressOpen
							? toggleAddressForm($event)
							: createAccountOpen
							? toggleCreateAccountOpen()
							: doBackToParentAccount($event)
					"
					@keyup.enter="
						profileEditable
							? toggleEditProfile($event)
							: addressOpen
							? toggleAddressForm($event)
							: createAccountOpen
							? toggleCreateAccountOpen()
							: doBackToParentAccount($event)
					"
				>
					{{ $t('profile.backToParent') }}
				</div> -->
				<!-- add title here -->
				<h1
					class="title profile-title profile-child-account-title"
					v-if="isChild && !profileEditable && createAccountOpen === false && addressOpen === false"
				>
					{{ $t('profile.familyAccountsLabel') }}
				</h1>
				<div
					v-if="isChild"
					class="button grey profile-child-account-button"
					tabindex="0"
					@mousedown="toggleDeleteAccountModal({ open: true })"
					@keyup.enter="toggleDeleteAccountModal({ open: true })"
				>
					{{ $t('profile.deleteFamilyAccount') }}
				</div>
			</div>
			<ProfileEdit
				v-if="profileEditable"
				ref="edit"
				:profile="isAdminEdit ? userInAdmin : currentUser"
				:isAdminEdit="isAdminEdit"
				:editLoading="editLoading"
				:isChild="isChild"
				@delete-mobib="toggleMobibModal"
				@update="doUpdateProfile"
				:createAccountOpen="createAccountOpen"
			/>
			<CreateAccount v-if="!!createAccountOpen" @toggleForm="toggleCreateAccountOpen" />
			<ProfileAddresses
				v-if="addressOpen"
				ref="profileAddressRef"
				:addressOpen="addressOpen"
				@toggleRequestModal="toggleRequestModal"
				@toggleDeleteModal="doOpenDeleteModal"
				@removeAddress="removeAddress"
				@toggleAddressForm="toggleAddressForm"
			/>
			<h1
				class="title profile-title"
				v-if="!isChild && !profileEditable && createAccountOpen === false && addressOpen === false"
			>
				{{ $t('profile.PageTitle') }}
			</h1>
			<div
				v-if="!profileEditable && createAccountOpen === false && addressOpen === false"
				class="profile-content"
			>
				<!-- <div class="profile-content-block"> -->
				<SessionActive v-if="APP_ENV_SESSION" />
				<!-- <div class="profile-title-section">
					 <div
							:class="{ active: profileEditable }"
							class="button blue activatable profile-action-button"
							tabindex="0"
							@mousedown="toggleEditProfile($event)"
							@keyup.enter="toggleEditProfile($event)"
						>
							{{ profileEditable ? $t('general.cancelLabel') : $t('profile.editProfileLabel') }}
						</div> 
				</div> -->
				<ProfileView
					v-if="!profileEditable"
					@toggleEditProfile="toggleEditProfile"
					:mobibLoading="mobibLoading"
					:profile="isAdminEdit ? userInAdmin : currentUser"
					:isChild="isChild"
					:isAdminEdit="isAdminEdit"
					@add="(number) => doUpdateMobib(number, 'add', 'view')"
					@toggleAccordion="toggleAccordion"
				/>

				<ProfileCompanyView
					v-if="!isChild && currentUser.company.name && !profileEditable && !isAdminEditChild"
					:company="isAdminEdit ? userInAdmin.company : currentUser.company"
				/>
				<ProfileAddresses
					ref="profileAddressRef"
					:addressOpen="addressOpen"
					@toggleRequestModal="toggleRequestModal"
					@toggleDeleteModal="doOpenDeleteModal"
					@removeAddress="removeAddress"
					@toggleAddressForm="toggleAddressForm"
					@toggleAccordion="toggleAccordion"
				/>
				<FamilyAccounts
					v-if="!isChild && !isAdminEdit"
					:familyAccounts="currentUser.children ? currentUser.children : []"
					@toggleForm="toggleCreateAccountOpen"
					@toggleAccordion="toggleAccordion"
					@onSwitchAccount="closeProfileEdit($event)"
				/>

				<ProfileTemporaryAccesses
					v-if="activeTemporaryAccess"
					:temporaryAccess="activeTemporaryAccess"
				/>
				<Accordion :title="$t('profile.requestTitle')" @click="toggleAccordion(3)" :isOpen="true">
					<ProfileRequests
						:subscriptions="subsToShow(currentUser.subscriptions)"
						:requests="activeRequests"
						:activeAddresses="activeAddresses"
						:disableCancelRequest="isAdminEdit"
						@toggleRequestModal="toggleRequestModal"
					/>

					<ProfileSubscriptions
						v-if="!isAdminEdit"
						:subscriptions="subsToShow(currentUser.subscriptions)"
						:proposals="activeProposals"
						@toggle-proposal-modal="toggleProposalModal"
						@toggle-cancel-sub-modal="toggleCancelSubModal"
					/>
				</Accordion>
				<Accordion
					v-if="APP_ENV_SESSION"
					:title="$t('sessionParking.sessionResult.sessionHistory')"
					@click="toggleAccordion(4)"
					:isOpen="true"
				>
					<ProfileSessionHistory :id="isAdminEdit ? userInAdmin.id : currentUser.id" />
				</Accordion>
				<Accordion
					v-if="!isChild"
					:title="$t('profile.paymentLabel')"
					@click="toggleAccordion(5)"
					:isOpen="true"
				>
					<PaymentMethod
						v-if="!isChild && !isAdminEdit"
						:subscriptions="subsToShow(currentUser.subscriptions)"
						:id="currentUser.id"
						:view="'profile'"
					/>
					<button
						class="button red"
						style="width: -webkit-fill-available"
						@click="goToUpdatePaymentMethod"
					>
						{{ $t('profile.paymentMethods.addLabel') }}
					</button>
				</Accordion>
				<!-- </div> -->
				<!-- <div class="profile-content-block"></div> -->
			</div>
			<h1
				class="title profile-title profile-title-mobile"
				v-if="isChild && !profileEditable && createAccountOpen === false && addressOpen === false"
			>
				{{ $t('profile.familyAccountsLabel') }}
			</h1>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import ProfileView from '@/components/profile/View.vue'
import ProfileEdit from '@/components/profile/Edit.vue'
import FamilyAccounts from '@/components/profile/FamilyAccounts.vue'
import CreateAccount from '@/components/profile/CreateAccount.vue'
import ProfileSubscriptions from '@/components/profile/Subscriptions.vue'
import ProfileTemporaryAccesses from '@/components/profile/TemporaryAccesses.vue'
import ProfileRequests from '@/components/profile/Requests.vue'
import ProfileAddresses from '@/components/profile/ProfileAddresses.vue'
import ProfileSessionHistory from '@/components/profile/SessionHistory.vue'
import SessionActive from '@/components/profile/SessionActive.vue'
import RequestModal from '@/components/RequestModal.vue'
import Loading from '@/components/Loading.vue'
import Modal from '@/components/Modal.vue'
import ValidationRequired from '@/mixins/validation-required'
import { subsToShow } from '@/store/modules/subscription'
import { find } from 'lodash/fp'
import { date } from '@/helpers'
import Checkbox from '@/components/form/Checkbox.vue'
import ProfileCompanyView from '@/components/profile/CompanyView.vue'
import Dropdown from '@/components/form/Dropdown.vue'
import Accordion from '@/components/Accordion.vue'
import { APP_ENV_SESSION } from '@/config'
import SessionModal from '../../components/SessionModal.vue'
import PreviousButton from '@/components/PreviousButton.vue'
import PaymentMethod from '@/components/mollie/PaymentMethod.vue'

// import { logger } from '@/logger'

export default {
	name: 'Profile',
	components: {
		ProfileView,
		ProfileEdit,
		ProfileAddresses,
		ProfileSubscriptions,
		ProfileTemporaryAccesses,
		ProfileRequests,
		Loading,
		Modal,
		RequestModal,
		Checkbox,
		FamilyAccounts,
		CreateAccount,
		ProfileCompanyView,
		Dropdown,
		ProfileSessionHistory,
		Accordion,
		SessionActive,
		SessionModal,
		PreviousButton,
		PaymentMethod,
	},
	mixins: [ValidationRequired],
	props: {
		editable: {
			default: false,
			type: Boolean,
		},
		proposal: {
			default: '',
			type: String,
		},
	},
	data() {
		return {
			profileEditable: this.editable,
			mobibLoading: false,
			editLoading: false,
			mobibModal: false,
			mobibType: '',
			proposalModal: false,
			proposalLoading: false,
			selectedProposalId: null,
			confirmProposalModal: false,
			cancelSubModal: false,
			bankAccountModal: false,
			cancelSubLoading: false,
			termsOfConditionProposal: false,
			toDeleteAddressId: '',
			createAccountOpen: false,
			deleteAccountModalOpen: false,
			deleteAccountModalLoading: false,
			familyAccountInfoOpen: false,
			addressOpen: false,
			rejectReason: {},
			rejectReasonOther: '',
			APP_ENV_SESSION,
		}
	},
	computed: {
		localizedData() {
			return {
				deleteFamilyTitle: this.$t('profile.deleteFamilyAccountMessage'),
				deleteFamilyAccountDisclaimer: this.$t('profile.deleteFamilyAccountDisclaimer'),
				deleteFamilyLabel: this.$t('profile.deleteFamilyLabel'),
				BankAccountModalLabel: this.$t('profile.bankAccount.heading'),
				BankAccountModalTitle: this.$t('profile.bankAccount.title'),
				confirmBankAccountModal: this.$t('profile.subscription.confirmCancelModal'),
				confirmBankAccountDisclaimer: this.$t('profile.bankAccount.disclaimer'),
				confirmCancelSubModal: this.$t('profile.subscription.confirmCancelModal'),
				confirmCancelSubDisclaimer: this.$t('profile.subscription.confirmCancelDisclaimer'),
				deleteSubscriptionLabel: this.$t('profile.subscription.deleteSubscriptionLabel'),
			}
		},
		...mapState({
			/* currentUser: (state) => state.profile.currentUser, */
			valid: (state) => state.profile.valid,
			profileError: (state) => {
				return { type: state.alert.type, message: state.alert.message }
			},
			selectedProposal() {
				return find({ id: this.selectedProposalId }, this.currentUser.proposals)
			},
			requestModalOpen: (state) => state.parkings.requestModalOpen,
			deleteModalOpen: (state) => state.parkings.deleteModalOpen,
			activeLanguage: (state) => state.language,
			isChild: (state) => state.profile.isChild,

			rejectOptions() {
				const reasons = this.$i18n
					.t('profile.proposal.rejectReasons')
					.map((r, idx) => ({ key: idx, value: r }))
					.concat([{ key: 'other', value: this.$i18n.t('profile.proposal.otherRejectReason') }])

				return reasons
			},
			isAdminEdit: (state) => state.profile.isAdminEdit,
			userInAdmin: (state) => state.profile.userInAdmin,
			isAdminEditChild: (state) => state.profile.isAdminEdit && state.profile.userInAdmin.parentId,
			currentAddresses: (state) =>
				state.profile.isAdminEdit
					? state.profile.userInAdmin.addresses
					: state.profile.currentUser.addresses,

			// familyAccounts: state => state.profile.currentUser.children,
		}),

		...mapGetters('profile', {
			hasBigParkingSubscription: 'hasBigParkingSubscription',
			activeProposals: 'activeProposals',
			activeTemporaryAccess: 'activeTemporaryAccess',
			activeRequests: 'activeRequests',
			activeAddresses: 'activeAddresses',
		}),
	},
	watch: {
		'currentUser.proposals'(newVal) {
			if (newVal && newVal.length > 0) {
				// show parking proposal popup after loaded from server
				this.showProposalLanding()
			}
		},
		isChild(newVal) {
			if (newVal && this.currentUser.addresses.length === 0) {
				this.familyAccountInfoOpen = true
			}
			this.scrollToTop()
		},
	},
	created() {},
	mounted() {
		if (this.isLoaded && !this.proposalModal) {
			this.showProposalLanding()
		}
		this.scrollToTop()
	},
	methods: {
		...mapActions({
			// loadCurrentUser: 'getCurrentUser',
			updateMobib: 'profile/updateMobib',
			updateProfile: 'profile/updateProfile',
			rejectProposal: 'profile/rejectProposal',
			cancelSubscription: 'subscription/cancelSubscription',
			cancelRequestSubscription: 'subscription/cancelRequestSubscription',
			toggleRequestModal: 'parkings/toggleRequestModal',
			toggleDeleteModal: 'parkings/toggleDeleteModal',
			removeAddress: 'profile/removeAddress',
			logError: 'logError',
			backToParentAccount: 'profile/backToParentAccount',
			deleteFamilyAccount: 'profile/deleteFamilyAccount',
			exitAdminEdit: 'profile/exitAdminEdit',
			updatePaymentMethod: 'profile/updatePaymentMethod',
			updateBankAccountForUser: 'admin/updateBankAccountForUser',
		}),
		...mapMutations('subscription', {
			// selectProposalForSub: 'selectProposalForSub',
		}),

		async doUpdateBankAccount() {
			try {
				const response = await this.updateBankAccountForUser({
					bankAccount: this.currentUser.bankAccount,
					userId: this.currentUser.id,
				})
				this.closeBankAccountModal()
				this.toggleCancelSubModal(this.selectedSubId)
			} catch (error) {
				this.currentUser.bankAccount = ''
			}
		},

		doBackToParentAccount(e) {
			this.addressOpen = false

			if (e) {
				e.preventDefault()
			}
			this.profileEditable = false
			return this.backToParentAccount()
		},
		closeProfileEdit(e) {
			if (e) {
				e.preventDefault()
			}
			this.profileEditable = false
		},
		doDeleteFamilyAccount(e) {
			if (e) {
				e.preventDefault()
			}

			this.deleteAccountModalLoading = true

			this.deleteFamilyAccount(this.currentUser.id).then(() => {
				this.deleteAccountModalLoading = false
				this.toggleDeleteAccountModal({ open: false })
			})
		},
		toggleEditProfile(e) {
			if (e) {
				e.preventDefault()
			}

			this.profileEditable = !this.profileEditable
			this.scrollToTop()
		},
		toggleMobibModal(close = false) {
			if (close) {
				this.mobibModal = false
			} else {
				this.mobibModal = !this.mobibModal
			}
		},
		toggleProposalModal(id) {
			this.selectedProposalId = id
			this.proposalModal = !this.proposalModal
		},
		closeProposalModal() {
			this.proposalModal = false
		},
		closeConfirmProposalModal() {
			this.confirmProposalModal = false
		},

		// doAcceptConfirmProposal() {
		// 	this.confirmProposalModal = !this.confirmProposalModal
		// },
		// doDeclineConfirmProposal() {
		// 	this.confirmProposalModal = !this.confirmProposalModal
		// },
		toggleBankAccountModal() {
			this.bankAccountModal = !this.bankAccountModal
		},
		closeBankAccountModal() {
			this.bankAccountModal = false
		},
		toggleCancelSubModal(id) {
			// get current subscription
			const currentSubscriptions = this.currentUser?.subscriptions.filter((sub) => sub.id === id)
			// get current deposits
			const currentDeposts = currentSubscriptions[0]?.deposits.filter(
				(dep) => dep.status === 3
			).length
			if (!this.currentUser.bankAccount && currentDeposts > 0) {
				this.selectedSubId = id
				this.toggleBankAccountModal()
			} else {
				this.selectedSubId = id
				this.cancelSubModal = !this.cancelSubModal
			}
		},
		closeCancelSubModal() {
			this.cancelSubModal = false
		},

		async doUpdateMobib(number, action, type) {
			this.mobibLoading = true
			this.mobibType = type

			try {
				await this.updateMobib(number)
				this.mobibLoading = false
				action === 'remove'
					? this.flash(this.$t('flashMessage.removeMobib'), 'success')
					: this.flash(this.$t('flashMessage.addMobib'), 'success')
				this.toggleMobibModal(true)
			} catch (error) {
				// this.flash(this.$t('error.mobibUpdateFailed'), 'error')
				this.mobibLoading = false
				this.toggleMobibModal(true)
			}
		},

		doRejectProposal() {
			this.closeProposalModal()
			this.confirmProposalModal = true
		},

		async confirmRejectProposal(keep) {
			this.proposalLoading = true

			try {
				await this.rejectProposal({
					proposalId: this.selectedProposalId,
					keep,
					rejectReason:
						this.rejectReason.key === 'other' ? this.rejectReasonOther : this.rejectReason.value,
				})
			} catch (error) {
				this.logError(`Error while rejecting proposal ${this.selectedProposalId}`)
			} finally {
				this.confirmProposalModal = false
				this.proposalLoading = false
			}
		},

		async confirmCancelSub() {
			this.cancelSubLoading = true

			try {
				// await this.cancelSubscription(this.selectedSubId)
				await this.cancelRequestSubscription(this.selectedSubId)
			} catch (error) {
				this.logError(`Error while canceling subscription ${this.selectedSubId}`)
			} finally {
				this.cancelSubModal = false
				this.cancelSubLoading = false
			}
		},

		// doKeepProposal() {
		// 	this.confirmProposalModal = false
		// },

		doAcceptProposal() {
			// this.selectProposalForSub(this.selectedProposal)
			this.$router.push({
				name: 'subscription',
				params: {
					subType: this.selectedProposal.parking.parkingTypeName,
				},
				query: {
					proposal: this.selectedProposal.id,
				},
			})
		},

		async doUpdateProfile(inputs) {
			this.editLoading = true

			await this.updateProfile(inputs)

			if (this.valid) {
				// this.flash(this.$t('flashMessage.profileUpdate'), 'success')
				this.toggleEditProfile()
			}

			// this.flash(this.$t('error.profileUpdateFailed'), 'error')
			this.editLoading = false
		},

		address(proposal) {
			const parking = proposal.parking

			return parking.legacyAddress[this.activeLanguage.value] || parking.legacyAddress.en
		},

		addressType(proposal) {
			const request = find({ id: proposal.requestId }, this.currentUser.requests)

			const address = request ? request.address : null

			return address ? address.addressType : ''
		},

		showProposalLanding() {
			if (this.proposal) {
				const prop = find({ urlCode: this.proposal }, this.currentUser.proposals)

				if (prop) {
					this.toggleProposalModal(prop.id)
					this.$router.push({ name: 'profile' })
				}
			}
		},
		doOpenDeleteModal(id) {
			this.toDeleteAddressId = id
			this.toggleDeleteModal()
		},
		async doConfirmDeleteModal() {
			await this.removeAddress(this.toDeleteAddressId)
			this.toDeleteAddressId = ''
			this.toggleDeleteModal()
		},
		doCancelDeleteModal() {
			this.toDeleteAddressId = ''
			this.toggleDeleteModal()
			const childComponent = this.$refs.childRef
			this.$refs.profileAddressRef.toggleDeleteAddressModal({ open: false })
			// this.$emit('toggleDeleteAddressModal', { open: false })
		},
		toggleCreateAccountOpen(option = {}) {
			if ('open' in option) {
				this.createAccountOpen = option.open
			} else {
				this.createAccountOpen = !this.createAccountOpen
			}
			this.scrollToTop()
		},

		toggleDeleteAccountModal(option = {}) {
			if ('open' in option) {
				this.deleteAccountModalOpen = option.open
			} else {
				this.deleteAccountModalOpen = !this.deleteAccountModalOpen
			}
		},
		toggleFamilyAccountInfo() {
			this.familyAccountInfoOpen = !this.familyAccountInfoOpen
		},
		toggleAddressForm() {
			this.addressOpen = !this.addressOpen
			this.scrollToTop()
		},

		selectRejectReason(option) {
			this.rejectReason = this.rejectOptions.find((rejectReason) => rejectReason.key === option.key)
		},

		isDefined(v) {
			return typeof v !== 'undefined'
		},
		doExitAdminEdit(e) {
			if (e) {
				e.preventDefault()
			}

			this.exitAdminEdit()
		},
		subsToShow,
		date,

		toggleAccordion(index) {
			this.$set(index, {
				active: !this.index.active,
			})
		},

		goToPreviousRoute() {
			this.$router.go(-1)
		},

		scrollToTop() {
			// Scroll to the top of the page
			window.scrollTo({ top: 0, behavior: 'smooth' })
		},

		async goToUpdatePaymentMethod() {
			if (this.isAdminEdit) {
				return false
			}
			if (this.currentUser.existsInStripe && !this.currentUser.existsInMollie) {
				const url = await this.updatePaymentMethod()
				window.location.href = url
			} else {
				this.$router.push({ name: 'MollieBilling' })
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/profile/index';

.accordion-item {
	@media (max-width: 768px) {
		// margin-left: 24px;
		// margin-right: 24px;
		width: auto;
	}
}
.active-session {
	background-color: #ffd024;
}
</style>
