import { render, staticRenderFns } from "./PreviousButton.vue?vue&type=template&id=dcb21f64&scoped=true"
import script from "./PreviousButton.vue?vue&type=script&lang=js"
export * from "./PreviousButton.vue?vue&type=script&lang=js"
import style0 from "./PreviousButton.vue?vue&type=style&index=0&id=dcb21f64&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcb21f64",
  null
  
)

export default component.exports